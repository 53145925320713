import React from "react"
import Layout from "../../components/layouts/Layout"
import { graphql, Link } from "gatsby"
import SEO from "../../components/Seo"
import BlockContent from "@sanity/block-content-to-react"
import YouTube from "react-youtube"

const serializers = {
  types: {
    youtube: ({ node }) => {
      const { videoID } = node
      return <YouTube videoId={videoID} />
    },
  },
}

const ArticlePage = ({ data }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data.sanityBlog.title,
    description: data.sanityBlog.description,
    image: "",
    author: {
      "@type": "Organization",
      name: "Archibald James Wine & Ciderworks",
    },
    publisher: {
      "@type": "Organization",
      name: "Archibald James Wine & Ciderworks",
      logo: {
        "@type": "ImageObject",
        url: "https://archibaldjames.com/logo.png",
      },
    },
    // datePublished: ""
  }
  return (
    <>
      <Layout>
        <SEO
          schemaMarkup={schema}
          title={data.sanityBlog.title}
          description={data.sanityBlog.description}
        />
        <article className="prose prose-lg mx-auto">
          <h1>{data.sanityBlog.title}</h1>
          <BlockContent
            blocks={data.sanityBlog._rawBody}
            serializers={serializers}
          ></BlockContent>
        </article>
      </Layout>
    </>
  )
}

export default ArticlePage

export const query = graphql`
  query ($id: String!) {
    sanityBlog(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
      _rawBody
    }
  }
`
